import React from 'react';
import { Formik, Form, Field, CccisdInput, CccisdSelect } from 'cccisd-formik';
import axios from 'cccisd-axios';
import Notification from 'cccisd-notification';

const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    state = {};

    onSubmit = values => {
        if (values.name) {
            this.storeGroup(values.name, values.zipcode, values.state);
        }
    };

    storeGroup = async (label, zipcode, state) => {
        const response = await axios.post(Boilerplate.route('api.nexus.group.store'), {
            label,
            type: 'organization',
            zipcode,
            state,
        });

        if (response.data.status === 'success') {
            this.props.getNewOrg({ label, id: response.data.data.id, zipcode });

            Notification({
                message: `${label} has been added as an Organization`,
                type: 'success',
                duration: 5000,
            });

            this.props.closeModal();
        } else if (response.data.status === 'error') {
            Notification({
                message: `${response.data.errors.label[0]}.`,
                type: 'danger',
                duration: 3000,
            });
        }
    };

    validate = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Organization name is required';
        }
        if (!values.zipcode) {
            errors.zipcode = 'Zip Code is required';
        }
        if (!values.state) {
            errors.state = 'State is required';
        }
        if (values.state === 'Other' && !values.otherState) {
            errors.otherState = 'You must specify "Other" state';
        }

        return errors;
    };

    render() {
        return (
            <Formik
                onSubmit={this.onSubmit}
                validate={this.validate}
                render={props => {
                    return (
                        <Form>
                            <Field name="name" component={CccisdInput} label="Organization Name" />
                            <Field name="zipcode" component={CccisdInput} label="Zip Code" />
                            <Field
                                name="state"
                                component={CccisdSelect}
                                options={this.props.stateOptions}
                                label="State"
                            />
                            {props.values.state === 'Other' && (
                                <Field
                                    name="otherState"
                                    component={CccisdInput}
                                    label="Please specify 'Other' state"
                                />
                            )}

                            <div className="alert alert-info" style={{ textAlign: 'center' }}>
                                You will be registered as an Org Admin for this new organization.
                            </div>
                            <button className="btn btn-primary" type="submit">
                                Submit
                            </button>
                        </Form>
                    );
                }}
            />
        );
    }
}
