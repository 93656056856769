import React from 'react';
import Modal from 'cccisd-modal';
import PropTypes from 'prop-types';
import { RegisterLoginForm } from 'cccisd-laravel-nexus';
import axios from 'cccisd-axios';
import style from './style.css';
import { Field, CccisdSelect, CccisdInput } from 'cccisd-formik';
import Notification from 'cccisd-notification';
import isEmail from 'validator/lib/isEmail';
import { client as apollo } from 'cccisd-apollo';
import orgListQuery from './orgList.graphql';
import OrgForm from './form.js';

const Boilerplate = window.cccisd.boilerplate;
const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class SignUp extends React.Component {
    static propTypes = {
        match: PropTypes.object,
        desiredRole: PropTypes.string,
    };

    modal = React.createRef();
    orgFormModal = React.createRef();

    state = {
        pawn: null,
        message: null,
        header: 'System Message',
        alertType: 'alert alert-info',
        destination: Boilerplate.url('/'),
        orgList: null,
    };

    componentDidMount = () => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        const value = params.role;
        this.setState({ roleParam: value });
    };

    handleSubmit = async values => {
        // REGISTER FORM SUBMIT
        const stateValue = values.state === 'Other' ? values.otherState : values.state;
        const orgId = this.state.newOrg ? this.state.newOrg.id : values.organization;
        const role = this.state.newOrg ? 'orgAdmin' : values.role;

        const response = await axios.post(Boilerplate.route('check.user.registration'), {
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
            email: values.email.trim(),
            username: values.email.trim(),
            state: stateValue,
            role,
            group: orgId,
        });

        // Check if this is a new user
        if (response) {
            if (response.data.status === 'success') {
                this.processRegistrationData(values, response.data.data);
            }
        }
    };

    getNewOrg = group => {
        this.setState({ newOrg: group });
        const buttons = document.getElementsByTagName('button');
        const buttonsArr = [].slice.call(buttons);
        const registerButton = buttonsArr.find(item => item.innerText === 'Register');
        setTimeout(() => {
            registerButton.click();
        }, 1000);
    };

    handleClick = event => {
        // MODAL BUTTON
        const { activate } = this.state;

        this.modal.current.close();
        if (activate) {
            window.location = this.state.destination;
        }
    };

    openOrgForm = () => {
        this.orgFormModal.current.open();
    };

    closeOrgForm = () => {
        this.orgFormModal.current.close();
    };

    processRegistrationData = async (values, data) => {
        if (data) {
            const user = data.user;

            const pawns = data.pawns || [];

            if (data.message === 'User added') {
                const match = pawns.find(item => item.id === user.id);

                if (match && pawns.length === 1) {
                    // Case - Brand New Pawn
                    this.setState({
                        pawn: { pawnId: data.user.id, pawnHash: data.user.random_hash },
                        destination: Boilerplate.url(
                            '/account/activate/' + data.user.user.activation_token
                        ),
                        message: `Thank you for registering with the Provider Well-being! Please click Continue to activate your account.`,
                        header: `Registration Success`,
                        alertType: 'alert alert-success',
                        activate: true,
                    });
                    this.modal.current.open();
                } else if (!match) {
                    // Case - Existing User w/ New Pawn
                    if (!user.user.activated_at) {
                        // activate user
                        this.setState({
                            pawn: { pawnId: data.user.id, pawnHash: data.user.random_hash },
                            destination: Boilerplate.url(
                                '/account/activate/' + data.user.user.activation_token
                            ),
                            message: `${user.role.label} role has been added to user ${user.user.email}! Please click Continue to activate your account.`,
                            header: `Registration Success`,
                            alertType: 'alert alert-success',
                            activate: true,
                        });
                        this.modal.current.open();
                    } else {
                        Notification({
                            message: `${user.role.label} role has been added to user ${user.user.email}! Please log-in to continue.`,
                            type: 'success',
                            duration: 5000,
                        });
                    }
                }
            } else if (data.message === 'User exists') {
                if (data.user.activated_at && data.pawns) {
                    Notification({
                        message: `This account already exists in the system please log-in to continue.`,
                        type: 'success',
                        duration: 3000,
                    });
                } else if (!data.user.activated_at) {
                    window.location = Boilerplate.url(
                        '/account/activate/' + data.user.activation_token
                    );
                }
            }
        }
    };

    validateAdditionalFields = values => {
        let errors = {};

        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!isEmail(values.email.trim())) {
            errors.email = 'Please enter a valid email';
        }

        if (!values.first_name) {
            errors.first_name = 'First name is required';
        }
        if (!values.last_name) {
            errors.last_name = 'Last name is required';
        }
        if (!values.state) {
            errors.state = 'State is required';
        }
        if (values.state === 'Other' && !values.otherState) {
            errors.otherState = 'You must specify "Other" state';
        }
        if (!values.role) {
            errors.role = 'Role is required.';
        }
        if (
            values.role &&
            values.role.includes('org') &&
            !values.organization &&
            !this.state.newOrg
        ) {
            errors.organization = 'Organization is required';
        }

        return errors;
    };

    getStatesSelectBox = () => {
        const statePawnFields = AppDefs.pawn.fields.find(f => f.handle === 'state').values;
        let selectBoxOptions = statePawnFields.map(x => ({ label: x.name, value: x.value }));
        selectBoxOptions.unshift({ label: '-- Select a State --', value: '' });
        return selectBoxOptions;
    };

    getOrgList = async stateName => {
        const res = await apollo.query({
            query: orgListQuery,
            fetchPolicy: 'network-only',
            variables: { state: stateName },
        });

        const list = res.data.groups.organizationList;

        const orgList = list.map(org => {
            return {
                value: org.group.groupId,
                label: `${org.group.label} ${org.fields.zipcode ? `(${org.fields.zipcode})` : ''}`,
            };
        });
        if (list.length < 1) {
            orgList.unshift({
                value: null,
                label: `--No Organizations have been created in ${stateName}--`,
            });
        } else {
            orgList.unshift({ value: null, label: '--Please Select an Organization--' });
        }
        this.setState({ orgList });
    };

    renderSelectBox = props => {
        const { values } = props;

        const isOrgRole = values.role && values.role.includes('org');
        const missingFields =
            !values.email ||
            !values.first_name ||
            !values.last_name ||
            !values.state ||
            !values.role;

        if (isOrgRole && !this.state.orgList && !missingFields) {
            this.getOrgList(values.state);
        }

        const roles = [
            { value: null, label: '-- Select a User Type --' },
            { value: 'orgTeamMember', label: 'Org Team Member' },
            { value: 'instructor', label: 'Provider' },
        ];

        return (
            <>
                <Field
                    name="role"
                    component={CccisdSelect}
                    options={roles}
                    label="I want to register as..."
                />
                <Field
                    name="state"
                    component={CccisdSelect}
                    options={this.getStatesSelectBox()}
                    label="State"
                    onChange={e => {
                        props.setFieldValue('state', e.target.value);
                        this.getOrgList(e.target.value);
                    }}
                />
                {values.state === 'Other' && (
                    <Field
                        name="otherState"
                        component={CccisdInput}
                        label="Please specify 'Other' state"
                    />
                )}
                {this.state.orgList && isOrgRole && !this.state.newOrg && (
                    <>
                        <Field
                            name="organization"
                            component={CccisdSelect}
                            options={this.state.orgList}
                            label="Organization"
                        />
                        <div style={{ marginBottom: '20px' }}>
                            Is your organization missing?{' '}
                            <a onClick={this.openOrgForm}>Click Here</a>
                        </div>
                    </>
                )}
                {this.state.newOrg && (
                    <Field
                        name="organization"
                        component={CccisdSelect}
                        options={[{ value: this.state.newOrg.id, label: this.state.newOrg.label }]}
                        label="Organization"
                    />
                )}
            </>
        );
    };

    getFormInitialValues = () => {
        let initialValues = {};

        if (Fortress.auth()) {
            initialValues = {
                first_name: Fortress.user.acting.user.first_name,
                last_name: Fortress.user.acting.user.last_name,
                email: Fortress.user.acting.user.email,
                role:
                    Fortress.user.acting.data_type === 'instructor'
                        ? 'orgTeamMember'
                        : 'instructor',
            };
        }
        if (this.state.roleParam && this.state.roleParam === 'org') {
            initialValues.role = 'orgTeamMember';
        }
        if (this.state.roleParam && this.state.roleParam === 'provider') {
            initialValues.role = 'instructor';
        }
        return initialValues;
    };

    render() {
        const url = this.props.match.params.pathName
            ? Boilerplate.url(`/${this.props.match.params.pathName}`)
            : Boilerplate.url(`/`);

        return (
            <div>
                <div className={style.formBox}>
                    <div className={style.captionBox}>
                        <div className={style.regMsg}>Need to register?</div>
                        <div className={style.logMsg}>Already have an account?</div>
                    </div>
                    <RegisterLoginForm
                        registerProps={{
                            onRegisterSubmit: this.handleSubmit,
                            renderAdditionalFields: props => {
                                return this.renderSelectBox(props);
                            },
                            validateAdditionalFields: this.validateAdditionalFields,
                            title: 'Register',
                            initialValues: this.getFormInitialValues(),
                        }}
                        loginProps={{
                            defaultRedirectUrl: url,
                            title: 'Log In',
                            buttonText: 'Log In',
                        }}
                        enableReinitialize
                    />
                </div>
                <Modal
                    size={this.state.pawn ? 'large' : 'medium'}
                    title={this.state.header}
                    contentStyle={{ width: 'fit-content' }}
                    ref={this.modal}
                    trigger={null}
                >
                    <div>
                        <h4 className={this.state.alertType}>{this.state.message}</h4>

                        <div style={{ width: '100%', textAlign: 'center', marginTop: '3em' }}>
                            <div className="btn btn-primary" onClick={this.handleClick}>
                                Continue
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    size="medium"
                    title="Add a New Organization"
                    contentStyle={{ width: 'fit-content' }}
                    ref={this.orgFormModal}
                    trigger={null}
                >
                    <div>
                        <OrgForm
                            closeModal={this.closeOrgForm}
                            getNewOrg={this.getNewOrg}
                            stateOptions={this.getStatesSelectBox()}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
